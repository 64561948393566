import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ViewTitle } from '../interfaces/system.interface';

@Injectable({
  providedIn: 'root',
})
export class PageNavigationService {
  private readonly _navItems = new BehaviorSubject<
    Array<{ title: string; ref: ElementRef; order: number; level: number }>
  >([{ title: 'Top', ref: undefined, order: 0, level: 2 }]);
  public level2NavItems: Array<{
    title: string;
    ref: ElementRef;
    order: number;
    level: number;
  }> = [];
  readonly navItems$ = this._navItems.asObservable();
  public get navItems() {
    return this._navItems.getValue();
  }
  public set navItems(value) {
    let single = value?.filter(
      (navItem) =>
        value
          ?.map((existing) => existing.title)
          .filter((title) => title === navItem.title)?.length < 2
    );
    let duplicates = value?.filter(
      (navItem) =>
        value
          ?.map((existing) => existing.title)
          .filter((title) => title === navItem.title)?.length > 1
    );
    let final = duplicates?.length > 0 ? [...single, duplicates[0]] : single;
    this._navItems.next(final.sort((a, b) => a.order - b.order));
    value?.forEach((newNavItem) => {
      if (
        !this.level2NavItems.some(
          (navItem) => navItem.title === newNavItem.title
        )
      ) {
        this.level2NavItems.push(newNavItem);
      }
    });
  }
  public selectedView: ViewTitle;
  constructor() {}
}
