import {
  PERMISSIONS,
  ROLETITLES,
  SpreadSheetSettings,
} from '@aksia-monorepo/shared-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { EntityTypeEnum, LiquidityStructureEnum } from '../enums/enums';

export const VIEWTITLES = {
  DEFAULT: 'Default',
  LEGAL_DOC: 'Legal Doc',
  DATA: 'Data',
  ONE_PAGER: 'One Pager',
  INVESTMENT_RESEARCH: 'Investment Research',
  RISK: 'Risk',
  CLIENT_OPS: 'Client Ops',
  CO_INVESTMENT: 'Co-Investment',
  SECONDARY: 'Secondary',
};

export type ViewTitle = typeof VIEWTITLES[keyof typeof VIEWTITLES];

export const VIEWS = {
  DEFAULT: {
    name: VIEWTITLES.DEFAULT,
    authorizations: [
      {
        role: ROLETITLES.AKSIA_EMPLOYEE,
        permission: PERMISSIONS.VIEW,
      },
      {
        role: ROLETITLES.LEGAL_DOC,
        permission: PERMISSIONS.WRITE,
      },
      {
        role: ROLETITLES.DATA,
        permission: PERMISSIONS.WRITE,
      },
      {
        role: ROLETITLES.ONE_PAGER,
        permission: PERMISSIONS.WRITE,
      },
      {
        role: ROLETITLES.INVESTMENT_RESEARCH,
        permission: PERMISSIONS.WRITE,
      },
      {
        role: ROLETITLES.RISK,
        permission: PERMISSIONS.WRITE,
      },
      {
        role: ROLETITLES.CLIENT_OPS,
        permission: PERMISSIONS.WRITE,
      },
    ],
  },
  LEGAL_DOC: {
    name: VIEWTITLES.LEGAL_DOC,
    authorizations: [
      {
        role: ROLETITLES.AKSIA_EMPLOYEE,
        permission: PERMISSIONS.VIEW,
      },
      {
        role: ROLETITLES.LEGAL_DOC,
        permission: PERMISSIONS.WRITE,
      },
    ],
  },
  DATA: {
    name: VIEWTITLES.DATA,
    authorizations: [
      {
        role: ROLETITLES.AKSIA_EMPLOYEE,
        permission: PERMISSIONS.VIEW,
      },
      {
        role: ROLETITLES.DATA,
        permission: PERMISSIONS.WRITE,
      },
    ],
  },
  ONE_PAGER: {
    name: VIEWTITLES.ONE_PAGER,
    authorizations: [
      {
        role: ROLETITLES.AKSIA_EMPLOYEE,
        permission: PERMISSIONS.VIEW,
      },
      {
        role: ROLETITLES.ONE_PAGER,
        permission: PERMISSIONS.WRITE,
      },
    ],
  },
  INVESTMENT_RESEARCH: {
    name: VIEWTITLES.INVESTMENT_RESEARCH,
    authorizations: [
      {
        role: ROLETITLES.AKSIA_EMPLOYEE,
        permission: PERMISSIONS.VIEW,
      },
      {
        role: ROLETITLES.INVESTMENT_RESEARCH,
        permission: PERMISSIONS.WRITE,
      },
    ],
  },
  RISK: {
    name: VIEWTITLES.RISK,
    authorizations: [
      {
        role: ROLETITLES.AKSIA_EMPLOYEE,
        permission: PERMISSIONS.VIEW,
      },
      {
        role: ROLETITLES.RISK,
        permission: PERMISSIONS.WRITE,
      },
    ],
  },
  CLIENT_OPS: {
    name: VIEWTITLES.CLIENT_OPS,
    authorizations: [
      {
        role: ROLETITLES.AKSIA_EMPLOYEE,
        permission: PERMISSIONS.VIEW,
      },
      {
        role: ROLETITLES.CLIENT_OPS,
        permission: PERMISSIONS.WRITE,
      },
    ],
  },
  CO_INVESTMENT: {
    name: VIEWTITLES.CO_INVESTMENT,
    authorizations: [
      {
        role: ROLETITLES.AKSIA_EMPLOYEE,
        permission: PERMISSIONS.VIEW,
      },
      {
        role: ROLETITLES.DATA,
        permission: PERMISSIONS.WRITE,
      },
      {
        role: ROLETITLES.ONE_PAGER,
        permission: PERMISSIONS.WRITE,
      },
      {
        role: ROLETITLES.INVESTMENT_RESEARCH,
        permission: PERMISSIONS.WRITE,
      },
      {
        role: ROLETITLES.RISK,
        permission: PERMISSIONS.WRITE,
      },
    ],
  },
  SECONDARY: {
    name: VIEWTITLES.SECONDARY,
    authorizations: [
      {
        role: ROLETITLES.AKSIA_EMPLOYEE,
        permission: PERMISSIONS.VIEW,
      },
      {
        role: ROLETITLES.DATA,
        permission: PERMISSIONS.WRITE,
      },
      {
        role: ROLETITLES.ONE_PAGER,
        permission: PERMISSIONS.WRITE,
      },
      {
        role: ROLETITLES.INVESTMENT_RESEARCH,
        permission: PERMISSIONS.WRITE,
      },
      {
        role: ROLETITLES.RISK,
        permission: PERMISSIONS.WRITE,
      },
    ],
  },
} as const;

export type View = typeof VIEWS[keyof typeof VIEWS];

export type NoteDTO = {
  entityId: number;
  entityTypeId: EntityTypeEnum;
  fieldName: string;
  modifiedBy: string;
  modifiedOn: string;
  note: string;
  system: string;
};

export interface ISections {
  liquidityStructure?: LiquidityStructureEnum;
  sections: Array<string>;
}

export interface IEntityLoad {
  parentEntityId: number;
  parentEntityTypeId: number;
  parentEntityLabel: string;
  entityTypeId: number;
  entityId: number;
  label: string;
}

export interface SearchEntity {
  entityId: number;
  entityTypeId: number;
  dataProviderEntityid: string;
  name: string;
  investmentProgramDataProviderEntityId?: number;
  investmentProgramName?: string;
  managementCompanyDataProviderEntityId?: number;
  managementCompanyName?: string;
}

export interface SearchRequest {
  Query: string;
  fundId?: number;
  managementCompanyId?: number;
  managementCompanyDataProviderEntityId?: number;
  investmentProgramId?: number;
}

export interface SearchResponse {
  funds: SearchEntity[];
  managementCompanies: SearchEntity[];
  investmentPrograms: SearchEntity[];
}

export interface propWithAlias {
  source?: string;
  prop: string;
  alias: string;
}

export interface propIf {
  source: string;
  prop: string;
  value: unknown;
}

export interface IErrorResult {
  errorObj: HttpErrorResponse;
  isError: boolean;
  entityId: number;
  entityTypeId: EntityTypeEnum;
}

export interface MapLocation {
  id: string;
  name: string;
  lat: number;
  lng: number;
  formatted: string;
}

export interface IAuditLog {
  message?: string;
  action: string;
  previousValue?: any;
  newValue: any;
  modifiedBy: string;
  modifiedOn: Date | string;
  propertyName: string;
  additionalInformation?: string;
}

export interface IShareClassAuditQuery {
  component: string;
  componentId: number;
  property: string;
}

export interface LoaderEntity {
  id: string;
  requests: string[];
}

export interface ISpreadSheetInfo {
  aumAmounts: number[];
  isVisible: boolean;
  settings: SpreadSheetSettings;
}
